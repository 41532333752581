import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import firebase from 'firebase/app';
import 'firebase/auth';
import { EXTENSION_ID, firebaseConfig } from '../config';

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

export default function Auth() {
  const [message, setMessage] = useState('Please wait while we load sign-in...');

  useEffect(() => {
    const runtime = chrome?.runtime ?? browser.runtime;
    if (!runtime) {
      setMessage('Error: browser is not supported');
      return;
    }

    async function getRedirectResult() {
      const result = await firebase.auth().getRedirectResult();
      if (result?.credential?.idToken) {
        setMessage('Sign-in was successful, redirecting back to extension...')
        // Proceed to pass idToken to our extension so that we can authenticate the user.
        runtime.sendMessage(EXTENSION_ID, { idToken: result.credential.idToken });
        return;
      }

      if (window.location.hash === '#guard') {
        // Prevent prompting for sign-in if the user has navigated back from the prompt already.
        return;
      } else {
        window.location.hash = 'guard';
      }
      // If currently not signed-in then prompt the user to sign-in.
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    }

    getRedirectResult().catch((err) => setMessage(`Error: ${err.message}`));
  }, [])

  return (
    <>
      <Helmet>
        <title>Sign in - Tabmanager.io</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div style={{ textAlign: 'center', paddingTop: 40 }}>{message}</div>
    </>
  );
}

